import * as client_hooks from '../../../src/hooks.client.ts';


export { matchers } from './matchers.js';

export const nodes = [
	() => import('./nodes/0'),
	() => import('./nodes/1'),
	() => import('./nodes/2'),
	() => import('./nodes/3'),
	() => import('./nodes/4'),
	() => import('./nodes/5'),
	() => import('./nodes/6'),
	() => import('./nodes/7'),
	() => import('./nodes/8'),
	() => import('./nodes/9'),
	() => import('./nodes/10'),
	() => import('./nodes/11'),
	() => import('./nodes/12'),
	() => import('./nodes/13'),
	() => import('./nodes/14'),
	() => import('./nodes/15'),
	() => import('./nodes/16'),
	() => import('./nodes/17'),
	() => import('./nodes/18'),
	() => import('./nodes/19'),
	() => import('./nodes/20'),
	() => import('./nodes/21'),
	() => import('./nodes/22'),
	() => import('./nodes/23'),
	() => import('./nodes/24'),
	() => import('./nodes/25'),
	() => import('./nodes/26'),
	() => import('./nodes/27'),
	() => import('./nodes/28'),
	() => import('./nodes/29'),
	() => import('./nodes/30'),
	() => import('./nodes/31'),
	() => import('./nodes/32'),
	() => import('./nodes/33'),
	() => import('./nodes/34'),
	() => import('./nodes/35'),
	() => import('./nodes/36'),
	() => import('./nodes/37')
];

export const server_loads = [2,3];

export const dictionary = {
		"/(ui)": [~11,[2]],
		"/(ui)/2fa/enter-code": [12,[2]],
		"/(ui)/2fa/request-code": [13,[2]],
		"/(ui)/3fa": [14,[2]],
		"/(blank)/error": [~5],
		"/(server-only)/integrations/saml/assert/[clientId]": [~8],
		"/(server-only)/integrations/[type]": [~7],
		"/(server-only)/intratool": [~9],
		"/(ui)/language": [17,[2]],
		"/(ui)/lesson/answer": [18,[2]],
		"/(ui)/lesson/content/pdf": [~19,[2,4]],
		"/(ui)/lesson/content/scorm": [~20,[2]],
		"/(ui)/lesson/content/slider": [~21,[2]],
		"/(ui)/lesson/content/video": [~22,[2]],
		"/(ui)/lesson/question": [~23,[2]],
		"/(ui)/lesson/summary": [~24,[2]],
		"/(blank)/local-scormbridge": [~6],
		"/(ui)/personal-data/gender": [25,[2]],
		"/(ui)/personal-data/pants": [26,[2]],
		"/(ui)/personal-data/shirt": [27,[2]],
		"/(ui)/personal-data/shoes": [28,[2]],
		"/(ui)/personal-data/summary": [29,[2]],
		"/(ui)/personal-data/vaccination": [30,[2]],
		"/(ui)/privacy": [~31,[2]],
		"/(server-only)/staffbase": [~10],
		"/(ui)/summary": [~32,[2]],
		"/test/icon-button": [35],
		"/test/icons": [36],
		"/test/text-input": [37],
		"/(ui)/thank-you": [33,[2]],
		"/(ui)/welcome": [~34,[2]],
		"/(ui)/[selfServiceConfigId]": [~15,[2,3]],
		"/(ui)/[selfServiceConfigId]/sign-up": [~16,[2,3]]
	};

export const hooks = {
	handleError: client_hooks.handleError || (({ error }) => { console.error(error) }),

	reroute: (() => {})
};

export { default as root } from '../root.svelte';